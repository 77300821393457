<template>
  <div v-cloak>
    <div v-if="agreement" class="tw-max-w-2xl tw-mx-auto tw-bg-white tw-rounded-lg tw-p-6">
      <div class="tw-mb-6">
        <p class="tw-text-sm tw-mb-2 tw-pb-8">
          <router-link to="/" class="tw-text-fagforbundetTextColor hover:tw-text-fagfordbundetRed tw-underline">HJEM</router-link> / 
          {{ formatAgreementNumber(agreement.attributes.number) }}
        </p>
        
        <h1 class="tw-text-3xl tw-font-bold tw-mb-2 tw-text-fagforbundetTextColor">{{ getExternalTitle() }}</h1>
        <div class="tw-flex tw-justify-between tw-items-center tw-mb-4">
          <p class="tw-text-xl tw-text-fagforbundetTextColor">
            <strong>{{ getAgreementTypeName(agreement.attributes.type) }} </strong> {{  }}
            <template v-if="currentVersion"> {{ getValidityPeriod() }}</template>
            <span v-if="!isCurrentVersion" class="tw-ml-2 tw-relative tw-group">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="tw-w-6 tw-h-6 tw-inline-block tw-pb-1" style="fill: #FFD700;">
                <path d="M256 32c14.2 0 27.3 7.5 34.5 19.8l216 368c7.3 12.4 7.3 27.7 .2 40.1S486.3 480 472 480L40 480c-14.3 0-27.6-7.7-34.7-20.1s-7-27.8 .2-40.1l216-368C228.7 39.5 241.8 32 256 32zm0 128c-13.3 0-24 10.7-24 24l0 112c0 13.3 10.7 24 24 24s24-10.7 24-24l0-112c0-13.3-10.7-24-24-24zm32 224a32 32 0 1 0 -64 0 32 32 0 1 0 64 0z"/>
              </svg>
              <span class="tw-absolute tw-left-1/2 tw-transform -tw-translate-x-1/2 tw-bg-fagforbundetTextColor tw-text-fagforbundetWhite tw-px-2 tw-py-1 tw-rounded tw-text-sm tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity tw-duration-300 tw-whitespace-nowrap tw-top-full tw-mt-2 ">
                Dette er ikke den gjeldende versjonen av denne avtalen.
              </span>
            </span>
          </p>
          <div v-if="formattedVersionYears.length" class="tw-relative">
            <button @click="toggleVersionDropdown" class="tw-text-fagfordbundetRed tw-text-[1.05rem] tw-px-4 tw-py-2 tw-rounded-2xl hover:tw-bg-fagfordbundetRed hover:tw-text-fagforbundetWhite transition-colors duration-200">
              Andre versjoner ▼
            </button>
            <ul v-if="showVersionDropdown" class="tw-absolute tw-right-0 tw-z-10 tw-bg-fagforbundetWhite tw-border tw-border-gray-300 tw-mt-1 tw-rounded tw-shadow-lg">
              <li v-for="version in formattedVersionYears" 
                  :key="version.id" 
                  class="tw-px-4 tw-py-2 tw-cursor-pointer"
                  :class="{
                    'tw-bg-fagfordbundetRed tw-text-fagforbundetWhite': version.id === currentVersionId,
                    'hover:tw-bg-fagforbundetGreyligt': version.id !== currentVersionId
                  }"
                  @click="() => {
                    loadVersion(version.id);
                  }">
                {{ version.years }}
              </li>
            </ul>
          </div>
        </div>
        <p class="tw-mb-1 tw-text-fagforbundetTextColor">Avtalenummer: <strong> {{ formatAgreementNumber(agreement.attributes.number) }}</strong></p>
        <p v-if="currentVersion" class="tw-mb-4 tw-text-fagforbundetTextColor">
          Gyldig: <strong> {{ formatDate(currentVersion.attributes.validFrom) }} - {{ formatDate(currentVersion.attributes.validTo) }}</strong>
        </p>
        <p v-if="currentVersion" class="tw-text-fagforbundetTextColor">{{ currentVersion.attributes.description }}</p>

        <!-- download of agreements/protocols/attachmens/addendum  -->
        <div class="tw-pb-5 tw-text-fagforbundetTextColor">
          <h2 class="tw-text-2xl tw-font-bold tw-mb-4 tw-pt-8 tw-text-fagforbundetTextColor">Last ned dokumenter</h2>

          <!-- Alert -->
          <div v-if="isMissingDocument && hasPreviousVersionWithFile" 
               class="tw-bg-[#d1ecf1] tw-border-[#bee5eb] tw-p-4 tw-mb-4">
            <div class="tw-flex">
              <div class="tw-flex-shrink-0">

              </div>
              <div class="tw-ml-6">
                <p class="tw-text-m tw-text-[#0c5460]">
                  <strong>Obs!</strong> Avtaleteksten er ikke klar enda. Vi oppdaterer avtalen så fort ny tekst foreligger. Enn så lenge kan du laste ned forrige versjon av avtalen under.
                </p>
              </div>
            </div>
          </div>
          
          <table class="tw-w-full tw-border-collapse tw-border-color-fagforbundetGreyligt tw-text-[1.0rem]">
            <tbody>
              <!-- Download agreement(Avtaletekst) -->
              <tr v-if="currentVersion || previousValidVersion" class="tw-border-b tw-border-t ">
                <td class="tw-py-4 tw-pr-4 tw-w-36">
                  <div>Avtaletekst</div>
                  <div v-if="isMissingDocument && previousValidVersion" class="tw-text-fagforbundetTextColor">
                    for {{ getVersionYears(previousValidVersion) }}
                  </div>
                </td>
                <td class="tw-py-4 tw-pr-4">
                  {{ isMissingDocument && previousValidVersion 
                     ? (previousValidVersion.attributes?.titleExternal || previousValidVersion.attributes?.title)
                     : (currentVersion?.attributes?.titleExternal || currentVersion?.attributes?.title) }}
                </td>
                <td class="tw-text-right tw-w-24 ">
                  <a v-if="(isMissingDocument && previousValidVersion?.attributes?.fileURL) || (!isMissingDocument && currentVersion?.attributes?.fileURL)" 
                     :href="isMissingDocument && previousValidVersion ? previousValidVersion.attributes.fileURL : currentVersion?.attributes?.fileURL" 
                     class="tw-text-fagfordbundetRed hover:tw-underline tw-whitespace-nowrap tw-inline-flex tw-items-center tw-gap-1">
                    Last ned 
                    <svg class="tw-w-4 tw-h-4 tw-ml-1 tw-shrink-0" viewBox="0 0 512 512">
                      <path d="M517 265l-252-246-42 42 187 172-410 0 0 65 410 0-187 172 42 42z" 
                            class="tw-fill-current" transform="rotate(90 256 256)"/>
                    </svg>
                  </a>
                </td>
              </tr>
              <!-- Download addendums(Tillegsdokument) -->
              <tr v-for="addendum in addendums" :key="addendum.id" class="tw-border-b tw-border-t">
                <td class="tw-py-4 tw-pr-4 tw-w-36">Tilleggsdokument</td>
                <td class="tw-py-4 tw-pr-4">{{ getTitle(addendum.attributes.title) }}</td>
                <td class="tw-text-right tw-w-24">
                  <a v-if="addendum.attributes.fileURL" 
                     :href="addendum.attributes.fileURL" 
                     class="tw-text-fagfordbundetRed hover:tw-underline tw-whitespace-nowrap tw-inline-flex tw-items-center tw-gap-1">
                    Last ned 
                    <svg class="tw-w-4 tw-h-4 tw-ml-1 tw-shrink-0" viewBox="0 0 512 512">
                      <path d="M517 265l-252-246-42 42 187 172-410 0 0 65 410 0-187 172 42 42z" 
                            class="tw-fill-current" transform="rotate(90 256 256)"/>
                    </svg>
                  </a>
                  <span v-else class="tw-text-fagforbundetTextColor">Ikke tilgjengelig</span>
                </td>
              </tr>
              
              <!-- Download protocols(Protokoll) -->
              <tr v-for="protocol in protocols" :key="protocol.id" class="tw-border-b tw-border-t">
                <td class="tw-py-4 tw-pr-4 tw-w-36">Protokoll</td>
                <td class="tw-py-4 tw-pr-4">{{ getTitle(protocol.attributes.title) }}</td>
                <td class="tw-text-right tw-w-24">
                  <a v-if="protocol.attributes.fileURL" 
                     :href="protocol.attributes.fileURL" 
                     class="tw-text-fagfordbundetRed hover:tw-underline tw-whitespace-nowrap tw-inline-flex tw-items-center tw-gap-1">
                    Last ned 
                    <svg class="tw-w-4 tw-h-4 tw-ml-1 tw-shrink-0" viewBox="0 0 512 512">
                      <path d="M517 265l-252-246-42 42 187 172-410 0 0 65 410 0-187 172 42 42z" 
                            class="tw-fill-current" transform="rotate(90 256 256)"/>
                    </svg>
                  </a>
                  <span v-else class="tw-text-fagforbundetTextColor">Ikke tilgjengelig</span>
                </td>
              </tr>
              
              <!-- Download attachments(Vedlegg) -->
              <tr v-for="attachment in attachments" :key="attachment.id" class="tw-border-b tw-border-t">
                <td class="tw-py-4 tw-pr-4 tw-w-36">Vedlegg</td>
                <td class="tw-py-4 tw-pr-4">{{ getTitle(attachment.attributes.title) }}</td>
                <td class="tw-text-right tw-w-24">
                  <a v-if="attachment.attributes.fileURL" 
                     :href="attachment.attributes.fileURL" 
                     class="tw-text-fagfordbundetRed hover:tw-underline tw-whitespace-nowrap">
                    Last ned ↓
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- Related Agreements -->
        <div v-if="dataLoaded">
          <div v-if="hasRelatedAgreements" class="tw-mt-8">
            <h2 class="tw-text-2xl tw-font-bold tw-mb-4 tw-text-fagforbundetTextColor">Relaterte avtaler</h2>
            
            <!-- Parent Agreements (Hovedavtaler) -->
            <div v-if="parentAgreements && parentAgreements.length">
              <router-link 
                v-for="parent in parentAgreements" 
                :key="parent?.id"
                :to="{ name: 'AgreementDetails', params: { id: parent?.id } }"
                class="tw-flex tw-justify-between tw-items-center tw-border-b tw-border-gray-200 tw-py-3 agreement-link group"
              >
                <div class="tw-flex tw-items-center tw-py-2">
                  <span class="tw-mr-4 tw-text-[1.0rem] tw-text-fagforbundetTextColor agreement-type tw-w-[140px] tw-whitespace-nowrap">{{ parent.typeName }}</span>
                  <span class="tw-text-[1.0rem] tw-text-fagforbundetTextColor agreement-title tw-flex-1">{{ parent.title }}</span>
                </div>
                <svg class="tw-w-4 tw-h-4 tw-ml-2 tw-fill-fagfordbundetRed tw-transform tw-transition-all tw-duration-200 tw-translate-x-0 group-hover:tw-translate-x-2 tw-shrink-0 tw-stroke-1" 
                     viewBox="0 0 512 512">
                  <path d="M517 265l-252-246-42 42 187 172-410 0 0 65 410 0-187 172 42 42z" 
                        class="tw-fill-current"/>
                </svg>
              </router-link>
            </div>
            
            <!-- Child Agreements (Tariffavtaler/Annen avtale) -->
            <div v-if="childAgreements?.length">
              <router-link 
                v-for="child in childAgreements" 
                :key="child?.id"
                :to="{ name: 'AgreementDetails', params: { id: child?.id } }"
                class="tw-flex tw-justify-between tw-items-center tw-border-b tw-border-t tw-border-gray-200 tw-py-3 agreement-link group"
              >
                <div class="tw-flex tw-items-center tw-py-2">
                  <span class="tw-mr-4 tw-text-[1.0rem] tw-text-fagforbundetTextColor agreement-type tw-w-[140px] tw-whitespace-nowrap">{{ child.typeName }}</span>
                  <span class="tw-text-[1.0rem] tw-text-fagforbundetTextColor agreement-title tw-flex-1">{{ child.titleExternal || child.title }}</span>
                </div>
                <svg class="tw-w-4 tw-h-4 tw-ml-[0.6rem] tw-fill-fagfordbundetRed tw-transform tw-transition-all tw-duration-200 tw-translate-x-0 group-hover:tw-translate-x-2 tw-shrink-0" 
                     viewBox="0 0 512 512">
                  <path d="M517 265l-252-246-42 42 187 172-410 0 0 65 410 0-187 172 42 42z" 
                        class="tw-fill-current"/>
                </svg>
              </router-link>
            </div>
          </div>
        </div>

      </div>
    <div>
    </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';





export default {
  name: 'AgreementDetails',
  props: {
    id: {
      type: String,
      required: true
    },
    initialVersionId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      agreement: null,
      currentVersion: null,
      relatedAgreements: [],
      fileUrl: '',
      fileTitle: '',
      agreementVersions: [],
      formattedVersions: [],
      showVersionDropdown: false,
      agreementVersionsId: [],
      currentVersionId: null,
      parentAgreements: null,
      childAgreements: null,
      dataLoaded: false,
      response: null,
    };
  },
  computed: {
    hasRelatedAgreements() {
      return this.dataLoaded && ((this.parentAgreements?.length > 0) || (this.childAgreements?.length > 0));
    },
    isCurrentVersion() {
      return this.currentVersionId === this.agreement.relationships.current.data.id;
    },
    protocols() {
      if (!this.response?.included || !this.currentVersion?.relationships?.protocols?.data) {
        return [];
      }
      
      const protocols = this.response.included.filter(item => {
        return item.type === 'protocols' && 
          this.currentVersion.relationships.protocols.data.some(p => p.id === item.id);
      });
      
      return protocols;
    },
    attachments() {
      if (!this.response?.included || !this.currentVersion?.relationships?.attachments?.data) {
        return [];
      }
      return this.response.included.filter(item => 
        item.type === 'attachments' && 
        this.currentVersion.relationships.attachments.data.some(a => a.id === item.id)
      );
    },
    addendums() {
      if (!this.response?.included || !this.currentVersion?.relationships?.addendums?.data) {
        return [];
      }
      return this.response.included.filter(item => 
        item.type === 'addendums' && 
        this.currentVersion.relationships.addendums.data.some(a => a.id === item.id)
      );
    },
    hasPreviousVersionWithFile() {

      if (!this.agreementVersions || this.agreementVersions.length === 0) {
        return false;
      }
      
      // Sort versions by validFrom date in descending order
      const sortedVersions = [...this.agreementVersions].sort((a, b) => 
        new Date(b.attributes.validFrom) - new Date(a.attributes.validFrom)
      );
      
      
      // Find the current version's index
      const currentIndex = sortedVersions.findIndex(v => v.id === this.currentVersionId);
      
      if (currentIndex === -1 || currentIndex === sortedVersions.length - 1) {
        return false;
      }
      
      // Check if any previous version has a valid fileURL
      const previousVersions = sortedVersions.slice(currentIndex + 1);
      
      const hasPreviousFile = previousVersions.some(v => {
        const fileUrl = v.attributes.fileURL;
        const isValid = fileUrl && !fileUrl.toLowerCase().endsWith('.azure.txt');
        return isValid;
      });
      
      return hasPreviousFile;
    },
    isMissingDocument() {
      const fileUrl = this.currentVersion?.attributes?.fileURL;
      return !fileUrl || fileUrl.toLowerCase().endsWith('.azure.txt');
    },
    previousValidVersion() {
      if (!this.agreementVersions?.length) return null;
      
      const sortedVersions = [...this.agreementVersions].sort((a, b) => {
        const dateA = new Date(b.attributes?.validFrom || 0);
        const dateB = new Date(a.attributes?.validFrom || 0);
        return dateA - dateB;
      });
      
      const currentIndex = this.currentVersionId 
        ? sortedVersions.findIndex(v => v.id === this.currentVersionId)
        : -1;
        
      if (currentIndex === -1 || currentIndex === sortedVersions.length - 1) return null;
      
      return sortedVersions.slice(currentIndex + 1).find(v => {
        const fileUrl = v.attributes?.fileURL;
        return fileUrl && !fileUrl.toLowerCase().endsWith('.azure.txt');
      });
    },
    formattedVersionYears() {
      const years = this.getFormattedVersionYears();
      return years;
    }
  },
  // watch: {
  //   '$route.params.id': {
  //     handler() {
  //       this.fetchAgreementDetails();
  //     },
  //     immediate: true
  //   }
  // },
  methods: {
    async fetchAgreementDetails() {
      try {

        this.currentVersion = null;
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${baseUrl}/agreement/${this.$route.params.id}?include=current,current.attachments,current.protocols,current.addendums,children,parents,versions,versions.addendums,versions.protocols,versions.attachments`);
        this.response = response.data;
        this.agreement = response.data.data;
        
        // Set up versions data first
        this.agreementVersionsId = this.agreement.relationships.versions.data;
        this.currentVersionId = this.agreement.relationships.current.data.id;
        this.agreementVersions = response.data.included.filter(
          item => item.type === 'agreement-versions'
        );
        this.agreementVersionCurrent = response.data.included.filter(
          item => item.type === 'agreement-versions' && item.id === this.currentVersionId
        );


        // Format versions for dropdown
        this.formattedVersions = this.getFormattedVersions();
        this.formattedVersionYears = this.getFormattedVersionYears();

        // Get current version ID and load it
        // if (this.agreement?.relationships?.current?.data?.id) {
        //   const currentVersionId = this.agreement.relationships.current.data.id;
        //   await this.loadVersion(currentVersionId);
        // }
        
        if (this.agreementVersionCurrent)
        {
          this.currentVersion = {
            attributes: {
              titleExternal: this.agreementVersionCurrent[0].attributes.titleExternal,
              title: this.agreementVersionCurrent[0].attributes.title,
              description: this.agreementVersionCurrent[0].attributes.description,
              validFrom: this.agreementVersionCurrent[0].attributes.validFrom,
              validTo: this.agreementVersionCurrent[0].attributes.validTo,
              fileURL: this.agreementVersionCurrent[0].attributes.fileURL
            },
            relationships: {
              protocols: response.data.included.filter(item => item.type === 'protocols'),
              attachments: response.data.included.filter(item => item.type === 'attachments'),
              addendums: response.data.included.filter(item => item.type === 'addendums'),
              agreement: response.data.included.filter(item => item.type === 'agreement'),
            }
          };
        }

        //this.$forceUpdate();

        // Load all version details
        // const versionPromises = this.agreementVersions.map(version => 
        //   axios.get(`${baseUrl}/AgreementVersion/${version.id}`)
        // );
        
        // const versionResponses = await Promise.all(versionPromises);
        // this.agreementVersions = versionResponses.map(response => response.data.data);
        this.relatedAgreement = response.data.included.find(item => item.type == 'agreements');
        this.relatedAgreementId = response.data.data.relationships.related.data;
        this.relatedAgreementChildren = response.data.data.relationships.children.data;
        
        // Process parent agreements
        this.parentAgreements = this.getRelatedAgreements(this.agreement.relationships.parents.data, response.data.included);

        // Process child agreements
        this.childAgreements = this.getRelatedAgreements(this.agreement.relationships.children.data, response.data.included);
        this.dataLoaded = true; 
      } catch (error) {
        console.error('Error fetching agreement details:', error);
        this.parentAgreements = [];
        this.childAgreements = [];
        this.dataLoaded = true; 
      } finally {
        this.dataLoaded = true;
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString('no-NO', { day: '2-digit', month: '2-digit', year: 'numeric' });
    },
    getValidityPeriod() {
      if (!this.currentVersion?.attributes?.validFrom || !this.currentVersion?.attributes?.validTo) {
        return '';
      }
      const fromYear = new Date(this.currentVersion.attributes.validFrom).getFullYear();
      const toYear = new Date(this.currentVersion.attributes.validTo).getFullYear();
      return `${fromYear}-${toYear}`;
    },
    getExternalTitle() {
      if (this.currentVersion && this.currentVersion.attributes) {
        if (this.currentVersion.attributes.titleExternal)
          return this.currentVersion.attributes.titleExternal;
        if (this.currentVersion.attributes.title)
          return this.currentVersion.attributes.title;
        return 'Ingen tittel tilgjengelig';
      }
      return 'Ingen tittel tilgjengelig';
    },
    formatAgreementNumber(number) {
      if (typeof number === 'string') {
        return number
          .replace(/^SPEKTER\s*/i, 'Spekter ')
          .replace(/^STAT\s*/i, 'Stat ');
      }
      return number;
    },
    getValidPeriods(){

    },
      
    getFormattedVersionYears() {
      return this.agreementVersionsId.map(versionRef => {
        const version = this.agreementVersions.find(v => v.id === versionRef.id);
        if (version) {
          const fromYear = new Date(version.attributes.validFrom).getFullYear();
          const toYear = new Date(version.attributes.validTo).getFullYear();
          return {
            id: version.id,
            years: `${fromYear} - ${toYear}`
          };
        }
        return null;
      }).filter(Boolean).sort((a, b) => {
        const [aStart] = a.years.split(' - ');
        const [bStart] = b.years.split(' - ');
        return parseInt(bStart) - parseInt(aStart); 
      });
    },
    getFormattedVersions() {
      return this.agreementVersionsId.map(versionRef => {
        const version = this.agreementVersions.find(v => v.id === versionRef.id);
        if (version) {
          return {
            id: version.id,
            validPeriod: `${this.formatDate(version.attributes.validFrom)} - ${this.formatDate(version.attributes.validTo)}`
          };
        }
        return null;
      }).filter(Boolean); 
    },
    toggleVersionDropdown() {
      this.showVersionDropdown = !this.showVersionDropdown;
    },
    async loadVersion(versionId) {
      this.showVersionDropdown = false;
      this.currentVersionId = versionId;
      
      try {
        if (this.currentVersion) {  
          const basePath = `/avtaler/${this.$route.params.id}`;
          const versionPath = `${basePath}/versjoner/${versionId}`;
          window.history.pushState({}, '', versionPath);
        }
        
        const baseUrl = process.env.VUE_APP_API_BASE_URL;
        const response = await axios.get(`${baseUrl}/AgreementVersion/${versionId}`);
        
        const versionData = response.data.data;
        const attributes = versionData.attributes;
        
        this.currentVersion = {
          attributes: {
            titleExternal: attributes.titleExternal,
            title: attributes.title,
            description: attributes.description,
            validFrom: attributes.validFrom,
            validTo: attributes.validTo,
            fileURL: attributes.fileURL
          },
          relationships: {
            protocols: versionData.relationships.protocols,
            attachments: versionData.relationships.attachments,
            addendums: versionData.relationships.addendums,
            agreement: versionData.relationships.agreement
          }
        };

        this.$forceUpdate();
      } catch (error) {
        console.error('Error loading version details:', error);
      }
    },
    async loadRelated(){
      //fetchAgreementDetails(this.$route.params.id)
    },
    getAgreementTypeName(type) {
      switch(type) {
        case 'HA':
          return 'Hovedavtale';
        case 'TA':
          return 'Tariffavtale';
        case 'AA':
          return 'Annen avtale';
        default:
          return ' ';
      }
    },
    getRelatedAgreements(relationshipData, includedData) {
      if (!relationshipData || !includedData) return [];
      
      return relationshipData.map(relation => {
        const agreement = includedData.find(item => 
          item.type === 'agreements' && item.id === relation.id
        );
        
        if (agreement) {
          // Find all versions for this agreement
          const versions = includedData.filter(item => 
            item.type === 'agreement-versions' && 
            item.relationships?.agreement?.data?.id === agreement.id
          );
          
          versions.sort((a, b) => 
            new Date(b.attributes.validFrom) - new Date(a.attributes.validFrom)
          );
          
          const currentVersion = versions[0] || agreement;
          const titleExternal = currentVersion.attributes?.titleExternal;
          const title = currentVersion.attributes?.title || agreement.attributes?.title;
          
          // Get the agreement type name based on the type attribute
          const typeName = this.getAgreementTypeName(agreement.attributes?.type);
          
          return {
            id: agreement.id,
            titleExternal: titleExternal,
            title: title || 'Untitled Agreement',
            number: agreement.attributes?.number,
            type: agreement.attributes?.type,
            typeName: typeName // Add the type name
          };
        }
        return null;
      }).filter(Boolean);
    },
    getParentAgreementTitle(parentId) {
      if (!parentId) return 'Unknown Agreement';

      // Find the parent agreement
      const parentAgreement = this.response?.included?.find(item => 
        item.type === 'agreements' && item.id === parentId
      );

      if (!parentAgreement) return 'Unknown Agreement';

      // Find the current version of the parent agreement
      const currentVersionId = parentAgreement.relationships?.current?.data?.id;
      if (!currentVersionId) return 'Unknown Agreement';

      const currentVersion = this.response?.included?.find(item => 
        item.type === 'agreement-versions' && item.id === currentVersionId
      );

      // Return the title from the current version, or a fallback if not found
      return currentVersion?.attributes?.titleExternal || 
             currentVersion?.attributes?.title || 
             'Unknown Agreement';
    },
    closeDropdownOnOutsideClick(event) {
      if (this.showVersionDropdown && !event.target.closest('.tw-relative')) {
        this.showVersionDropdown = false;
      }
    }, 
    getTitle(title) {
      if (title === null)
        return '- uten tittel -'
      return title
        .trim();
    },
    getVersionYears(version) {
      if (!version?.attributes?.validFrom || !version?.attributes?.validTo) return '';
      const fromYear = new Date(version.attributes.validFrom).getFullYear();
      const toYear = new Date(version.attributes.validTo).getFullYear();
      return `${fromYear}-${toYear}`;
    }
  },
  async mounted() {
    await this.fetchAgreementDetails();
    
    // If we have an initialVersionId (from direct URL access), load that version
    // if (this.initialVersionId) {
    //   await this.loadVersion(this.initialVersionId);
    // } else {
    //   // Otherwise load the current version
    //   const currentVersionId = this.agreement?.relationships?.current?.data?.id;
    //   if (currentVersionId) {
    //     await this.loadVersion(currentVersionId);
    //   }
    // }
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdownOnOutsideClick);
  }
}
</script>
<style>
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.0rem;
  line-height: 1.5;
}
</style>
<style scoped>

.agreement-link:hover .agreement-type,
.agreement-link:hover .agreement-title {
  color: #fc3729;
}

.agreement-link:hover .agreement-title,
.agreement-link:hover .agreement-type
{
  text-decoration: underline;
}

.debug-span:hover .debug-tooltip {
  opacity: 1 !important;
}

.arrow-icon {
  position: relative;
  left: 0;
  transition: left ease 0.3s;
}

.agreement-link:hover .arrow-icon {
  left: 7px;
}

svg {
  fill: #fc3729; 
  transition: transform 0.2s ease-in-out;
}

.group:hover svg {
  transform: translateX(0.75rem); 
}

.agreement-link svg {
  fill: #fc3729; 
}




</style>
